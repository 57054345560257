//IServicesInfo is meant to be expanded to hold data to support the open/closed info based on the time.
export interface IServicesInfo {
    type: string;
    text: string;
}

export enum TServiceTypeText {
    ORDER_AHEAD = 'Order Ahead',
    PICKUP = 'Pickup',
    DRIVE_THROUGH = 'Drive Through',
    CURBSIDE_PICKUP = 'Curbside Pickup',
    DELIVERY = 'Delivery',
    STALL = 'Stall',
    STORE = 'Store',
    HAPPY_HOUR = 'Happy Hour',
    LATE_NIGHT_HAPPY_HOUR = 'Late Night Happy Hour',
    OUTER = 'Outer',
}
