import {
    IMenuCategoryFields,
    IPageFields,
    IProductFields,
    ITapListMenuCategoryFields,
} from '../../@generated/@types/contentful';

import { InspireCmsEntry } from '../types';

export const getPDPPageDescription = (product: InspireCmsEntry<IProductFields>): string =>
    product.fields.metaDescription || product.fields.name;

export const getPageDescription = (page: InspireCmsEntry<IPageFields>): string => page.fields.metaDescription || '';

export const getPageDescriptionByMetaInfo = (metaDescription = ''): string => metaDescription;

export const getMenuCategoryPageDescription = (
    menuCategory: InspireCmsEntry<IMenuCategoryFields | ITapListMenuCategoryFields>
): string => menuCategory.fields.metaDescription || menuCategory.fields.categoryName;

export const getCityPageDescription = (template: string, stateCode: string, city: string): string => {
    const result: string = template?.replace(/{state_abbreviation}/gi, stateCode).replace(/{city}/gi, city);
    return result || '';
};

export const getStatePageDescription = (template: string, state: string): string => {
    const result: string = template?.replace(/{state}/gi, state);
    return result || '';
};
