import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { SyntheticEvent } from 'react';
import shouldAllowOAOnLocation from '../../../../../../common/helpers/locations/shouldAllowOAOnLocation';
import { IOpeningHoursInfo } from '../../../../../../common/helpers/locations/types';
import { InspireButton } from '../../../../../atoms/button';
import { CLOSED_LOCATION_PRIMARY_BUTTON_TEXT, OPEN_LOCATION_PRIMARY_BUTTON_TEXT } from './constants';

import styles from './locationsListItem.module.css';

interface IDefaultPrimaryCta {
    onOrderClick?: () => void;
    isOnlineOrderAvailable: boolean;
    locationSet: (e: SyntheticEvent) => void;
    redirectUrl: string;
    openingHoursInfo: IOpeningHoursInfo;
}

const DefaultPrimaryCta = (props: IDefaultPrimaryCta) => {
    const { isOnlineOrderAvailable, onOrderClick, locationSet, redirectUrl, openingHoursInfo } = props;
    const router = useRouter();

    const handleClickFilledPrimaryCta = (e: SyntheticEvent) => {
        onOrderClick?.();
        locationSet(e);
        redirectUrl && router.push(redirectUrl);
    };

    const handleClickOutlinedPrimaryCta = (e: SyntheticEvent) => {
        locationSet(e);
        router.push(redirectUrl);
    };

    if (shouldAllowOAOnLocation(openingHoursInfo, isOnlineOrderAvailable)) {
        return (
            <InspireButton
                className={classnames(styles.filledButton)}
                onClick={handleClickFilledPrimaryCta}
                text={OPEN_LOCATION_PRIMARY_BUTTON_TEXT}
                type="small"
            />
        );
    }

    return (
        <InspireButton
            className={classnames(styles.outlinedButton)}
            onClick={handleClickOutlinedPrimaryCta}
            text={CLOSED_LOCATION_PRIMARY_BUTTON_TEXT}
            type="secondary"
            size="small"
        />
    );
};

export default DefaultPrimaryCta;
