import { LOCATION_STATUS_TYPE } from './getOpeningHoursInfo';
import { IShouldAllowOAOnLocation } from './types';

const statusStopList = [
    LOCATION_STATUS_TYPE.TemporarilyUnavailable,
    LOCATION_STATUS_TYPE.PermanentlyOrTemporarilyClosed,
];

const shouldAllowOAOnLocation: IShouldAllowOAOnLocation = (storeStatus, isOnlineOrderAvailable) =>
    isOnlineOrderAvailable && !statusStopList.includes(storeStatus?.status);

export default shouldAllowOAOnLocation;
