import React, { FC } from 'react';
import TextWithDotSeparator from '../../../../../atoms/TextWithDotSeparator';
import { ILocationsListItemLayout } from './types';
import {
    SHOW_FULL_WIDTH_TITLE,
    SHOW_DISTANCE_AND_STORE_ID,
    SHOW_MILES_AT_BOTTOM,
    SHOW_MILES_AT_TOP,
} from './constants';

import styles from './locationsListItemLayout.module.css';

const LocationsListItemLayout: FC<ILocationsListItemLayout> = ({
    title,
    primaryCta,
    secondaryCta,
    address,
    openingHours,
    distance,
    storeId,
    services,
    referencePoint,
}) => {
    return (
        <>
            {SHOW_FULL_WIDTH_TITLE && <div className={styles.title}>{title}</div>}
            <div className={styles.wrapper}>
                <div className={styles.leftColumn}>
                    {!SHOW_FULL_WIDTH_TITLE && <div className={styles.title}>{title}</div>}
                    {referencePoint && <div className={styles.referencePoint}>{referencePoint}</div>}
                    <div className={styles.address}>{address}</div>
                    {SHOW_DISTANCE_AND_STORE_ID && (
                        <div className={styles.distanceAndStoreId}>
                            <TextWithDotSeparator leftText={SHOW_MILES_AT_BOTTOM ? distance : ''} rightText={storeId} />
                        </div>
                    )}
                    <div className={styles.openingHours}>{openingHours}</div>
                    <div>{services}</div>
                    <div className={styles.secondaryCta}>{secondaryCta}</div>
                </div>
                <div className={styles.rightColumn}>
                    {SHOW_MILES_AT_TOP ? distance : ''}
                    {primaryCta}
                </div>
            </div>
        </>
    );
};

export default LocationsListItemLayout;
