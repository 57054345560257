import getBrandInfo from '../../lib/brandInfo';
import { SHOW_BRAND_NAME } from '../constants/getPageTitleConstants';
import { SHOW_FIND_IN_PAGE_TITLE } from '../../components/organisms/locations/cityLocationsPageLayout/constants';

export const getHomePageTitle = (brandName = '', text = ''): string => `${brandName} | ${text}`;

export const getPdpPageTitle = (brandName = '', productName = ''): string =>
    `${productName} - Nearby For Delivery or Pick Up | ${brandName}`;

export const getMenuCategoryPageTitle = (brandName = '', categoryName = ''): string =>
    `${categoryName} Nearby For Delivery or Pick Up | ${brandName}`;

export const getPageTitle = (brandName = '', text = ''): string => `${text} | ${brandName}`;

export const getLocationDetailsPageTitle = (template = '', locationName = 'Location Details'): string => {
    const { brandName } = getBrandInfo();
    const result = template.replace(/{location}/gi, locationName);
    return result ? `${result} | ${brandName}` : `${locationName} | ${brandName}`;
};

export const getCityLocationsPageTitle = (template = '', state = '', city = ''): string => {
    const { brandName } = getBrandInfo();
    const result = template.replace(/{state}/gi, state).replace(/{city}/gi, city);
    return `${brandName} ${result}`;
};

export const getStateLocationsPageTitle = (template = '', state = ''): string => {
    const { brandName } = getBrandInfo();
    const result = template.replace(/{state}/gi, state);
    return SHOW_BRAND_NAME ? `${brandName} ${result}` : `${result}`;
};

/**
 * Method to get the City Locations Page Title
 * @method getCityLocationsTitle
 * @param {string} template  title format text
 * @param {string} city city name
 * @param {number} count store count
 * @author J Siva NagaRaju <siva.j@inspirebrands.com>
 * @added 22-02-2023
 * @version 1.0
 * @returns {string}  returns page title
 * @example
 * const name = 'Alabaster';
 * const count = 1;
 * const pageTitle = getCityLocationsTitle('find an Arby's in {city} near you', name, count);
 * <h1>{pageTitle}</h1>
 */

export const getCityLocationsTitle = (template = '', city = '', count = 0) => {
    const result = template.replace(/{city}/gi, city).replace(/{count}/gi, `${count}`);
    return SHOW_FIND_IN_PAGE_TITLE ? result : `${result} ${count === 1 ? 'location' : 'locations'}`;
};
