export const ADDRESS_LINE_CLASSNAME = 'truncate t-paragraph-small';
export const UNAVAILABLE_MESSAGE = 'Restaurant hours currently unavailable';
export const SHOW_UNAVAILABLE = true;
export const OPEN_LOCATION_PRIMARY_BUTTON_TEXT = 'ORDER';
export const CLOSED_LOCATION_PRIMARY_BUTTON_TEXT = 'VIEW MENU';
export const SHOW_MARKER_IN_TITLE = false;
export const SHOW_LIST_NUMBER_IN_TITLE = true;
export const SHOW_DISTANCE_IN_TITLE = false;
export const SHOW_SERVICES_LIST_AS_GRID = true;
export const SHOW_ADDRESS_IN_TITLE = false;
export const SHOW_ADDRESS_IN_LAYOUT = true;
export const CAN_CHECKOUT_CHANGE_LOCATION = false;
