import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { SyntheticEvent } from 'react';
import { useAppDispatch } from '../../../../../../redux/store';
import { GTM_VIEW_DEALS, GTM_MAKE_MY_STORE } from '../../../../../../common/services/gtmService/constants';
import { InspireButton } from '../../../../../atoms/button';

import styles from './locationsListItem.module.css';

export interface IDealsPrimaryCta {
    locationSet: (e: SyntheticEvent) => void;
    redirectUrl: string;
    hasLocationOffers: boolean;
    isRewardsLoading: boolean;
    isOnlineOrderAvailable: boolean;
    dataTestId?: string;
    locationId: string;
    displayName: string;
}

const DealsPrimaryCta = (props: IDealsPrimaryCta) => {
    const {
        locationSet,
        redirectUrl,
        hasLocationOffers,
        isRewardsLoading,
        isOnlineOrderAvailable,
        dataTestId,
        locationId,
        displayName,
    } = props;
    const router = useRouter();
    const dispatch = useAppDispatch();

    const handleClickPrimaryCta = (e: SyntheticEvent) => {
        locationSet(e);
        router.push(redirectUrl);

        dispatch({ type: GTM_VIEW_DEALS });
        dispatch({
            type: GTM_MAKE_MY_STORE,
            payload: { id: locationId, name: displayName },
        });
    };

    if (isRewardsLoading) {
        return null;
    }

    if (!hasLocationOffers) {
        return (
            <div className={classnames(styles.noItemsText)} data-testid={`${dataTestId}-no-deals`}>
                No Deals available for this store
            </div>
        );
    }

    if (!isOnlineOrderAvailable) {
        return (
            <InspireButton
                className={classnames(styles.outlinedButton, styles.viewDealsButton)}
                onClick={handleClickPrimaryCta}
                text="View Deals"
                type="secondary"
                size="small"
                dataTestId={dataTestId}
            />
        );
    }

    return (
        <InspireButton
            className={classnames(styles.filledButton, styles.viewDealsButton)}
            onClick={handleClickPrimaryCta}
            text="View Deals"
            type="small"
            dataTestId={dataTestId}
        />
    );
};

export default DealsPrimaryCta;
