import React, { useRef, useEffect, SyntheticEvent, Fragment } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useAppDispatch } from '../../../../../../redux/store';
import { ILocationWithDetailsModel } from '../../../../../../@generated/webExpApi';
import { PickupAddress } from '../../../../../../redux/orderLocation';
import InspireChip from '../../../../../atoms/Chip';
import { getLocationAddressString, isLocationOrderAheadAvailable } from '../../../../../../lib/locations';
import { getOpeningHoursInfo } from '../../../../../../common/helpers/locations/getOpeningHoursInfo';
import styles from './locationsListItem.module.css';
import getLocationDetailsPageUrl from '../../../../../../common/helpers/getLocationDetailsPageUrl';
import { InspireLink, InspireLinkButton } from '../../../../../atoms/link';
import LocationsListItemLayout from '../locationsListItemLayout';
import {
    ADDRESS_LINE_CLASSNAME,
    CAN_CHECKOUT_CHANGE_LOCATION,
    SHOW_ADDRESS_IN_LAYOUT,
    SHOW_ADDRESS_IN_TITLE,
    SHOW_DISTANCE_IN_TITLE,
    SHOW_LIST_NUMBER_IN_TITLE,
    SHOW_MARKER_IN_TITLE,
    SHOW_SERVICES_LIST_AS_GRID,
    SHOW_UNAVAILABLE,
    UNAVAILABLE_MESSAGE,
} from './constants';
import { GTM_ONLINE_ORDER_COMING_SOON } from '../../../../../../common/services/gtmService/constants';
import BrandIcon from '../../../../../atoms/BrandIcon';
import { isLocationListEnhancementEnabled } from '../../../../../../lib/getFeatureFlags';
import DefaultPrimaryCta from './defaultPrimaryCta';
import DealsPrimaryCta from './dealsPrimaryCta';
import { useRewards } from '../../../../../../redux/hooks';
import { getServices } from '../../../../locationInfo/locationServices/helpers';

export interface ILocationsListItemProps {
    location: PickupAddress;
    onLocationSet: (place: PickupAddress) => void;
    onLocationSelect: (place: PickupAddress) => void;
    selected: boolean;
    isCurrentLocation: boolean;
    listNumber?: number;
    hideStoreFeatures?: boolean;
    hideSelectStoreLink?: boolean;
    isOAEnabled: boolean;
    onLocationOnlineOrderClick?: (location: ILocationWithDetailsModel) => void;
    onMakeMyStoreClick?: (location: ILocationWithDetailsModel) => void;
    onOrderClick?: (location: ILocationWithDetailsModel) => void;
}

const LocationsListItem = (props: ILocationsListItemProps): JSX.Element => {
    const {
        location,
        onLocationSet,
        onLocationSelect,
        selected,
        isCurrentLocation,
        listNumber,
        hideStoreFeatures,
        hideSelectStoreLink,
        isOAEnabled,
        onLocationOnlineOrderClick,
        onMakeMyStoreClick,
        onOrderClick,
    } = props;
    const { displayName, id: locationId, distance, contactDetails } = location;
    const address = getLocationAddressString(location, SHOW_ADDRESS_IN_LAYOUT);
    const isOnlineOrderAvailable = isLocationOrderAheadAvailable(location, isOAEnabled);
    const distanceString = `${Math.round(distance.amount || 0)} ${distance.unit}`;

    const dispatch = useAppDispatch();

    const elementRef = useRef<HTMLDivElement>(null);
    const router = useRouter();
    const rewards = useRewards();
    const backTo = (Array.isArray(router.query.backTo) ? router.query.backTo[0] : router.query.backTo) || '/menu';
    const isRedirectCheckout = backTo === '/checkout';
    const backToUrl = CAN_CHECKOUT_CHANGE_LOCATION && isRedirectCheckout ? null : backTo;

    useEffect(() => {
        if (selected) {
            const parentElement = elementRef?.current?.parentNode as HTMLElement | null;
            if (parentElement && elementRef?.current?.offsetTop >= 0) {
                parentElement.scrollTop = elementRef.current.offsetTop;
            }
        }
    }, [selected]);

    const locationOnlineOrderSet = (e: SyntheticEvent) => {
        onLocationOnlineOrderClick?.(location);
        locationSet(e);

        dispatch({
            type: GTM_ONLINE_ORDER_COMING_SOON,
            payload: { storeName: displayName, storeId: locationId },
        });
    };

    const locationSet = (e: SyntheticEvent) => {
        e.stopPropagation();
        onLocationSet(location);
    };

    const handleMakeMyStoreClick = (e: SyntheticEvent) => {
        onMakeMyStoreClick?.(location);
        locationSet(e);
    };

    const handleStoreInfoClick = () => {
        router.push(detailsPageUrl);
    };

    const handleSelectLocation = () => {
        onLocationSelect(location);
    };

    const handleSelectLocationKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSelectLocation();
        }
    };

    const detailsPageUrl = getLocationDetailsPageUrl(location);

    const storeInfoCta = () => {
        return isLocationListEnhancementEnabled() ? (
            <InspireLinkButton
                className={styles.secondaryCtaButtonLayout}
                linkType="secondary"
                onClick={handleStoreInfoClick}
            >
                STORE INFO
            </InspireLinkButton>
        ) : undefined;
    };

    const renderSecondaryCta = () => {
        if (hideSelectStoreLink) {
            return null;
        }

        if (isCurrentLocation) {
            return (
                <div className={styles.myStoreWrapper}>
                    <div className={styles.secondaryCtaButtonLayout}>
                        <InspireChip label="MY STORE" />
                    </div>
                    {storeInfoCta()}
                </div>
            );
        }
        if (isOnlineOrderAvailable) {
            return (
                <Fragment>
                    <InspireLinkButton
                        className={styles.secondaryCtaButtonLayout}
                        linkType="secondary"
                        onClick={handleMakeMyStoreClick}
                        dataTestId={`location-item-make-my-store-button-${locationId}`}
                    >
                        MAKE MY STORE
                    </InspireLinkButton>
                    {storeInfoCta()}
                </Fragment>
            );
        }
        return (
            <InspireLink
                link="/menu"
                onClick={locationOnlineOrderSet}
                type="secondary"
                dataTestId={`location-item-online-coming-soon-${locationId}`}
            >
                ONLINE ORDERING COMING SOON
            </InspireLink>
        );
    };

    const renderTitle = () => {
        const title = SHOW_ADDRESS_IN_TITLE ? contactDetails.address.line1 : displayName;

        return (
            <div className={styles.titleWrapper}>
                {SHOW_MARKER_IN_TITLE && (
                    <span className={styles.marker}>
                        <span className={classnames('t-paragraph-small', styles.listNumber)}>{listNumber}</span>
                        <BrandIcon icon="navigation-map-pin" className={styles.markerIcon} />
                    </span>
                )}
                <h2
                    className={classnames('truncate-at-3', 't-subheader-smaller', styles.title, {
                        [styles.withLink]: detailsPageUrl,
                        [styles.titleFontSize]: isLocationListEnhancementEnabled(),
                    })}
                    title={title}
                >
                    {SHOW_LIST_NUMBER_IN_TITLE && listNumber && <span>{listNumber}. </span>}
                    {detailsPageUrl && !isLocationListEnhancementEnabled() ? (
                        <InspireLink
                            link={detailsPageUrl}
                            newtab={false}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={styles.detailsPageLink}
                        >
                            {title}
                        </InspireLink>
                    ) : (
                        title
                    )}
                </h2>
                {SHOW_DISTANCE_IN_TITLE && <div className={styles.distance}>{distanceString}</div>}
            </div>
        );
    };

    const renderServices = () => {
        if (
            !hideStoreFeatures &&
            ((Array.isArray(location.services) && location.services.length != 0) || location.features?.length)
        ) {
            const locationServices = location.services
                ?.filter((service) => service.type)
                ?.map((service) => service.type);

            const services: string[] = getServices(locationServices, location.features);
            const servicesText = services.join(', ');

            if (isLocationListEnhancementEnabled()) {
                return (
                    <div className={styles.servicesList}>
                        <div className={styles.serviceInfoTypes} data-testid="serviceInfo">
                            {servicesText}
                        </div>
                    </div>
                );
            }

            if (SHOW_SERVICES_LIST_AS_GRID) {
                return (
                    <div className={styles.servicesList}>
                        {services.map((service) => {
                            return (
                                <div className={styles.serviceInfo} data-testid="serviceInfo" key={service}>
                                    {service}
                                </div>
                            );
                        })}
                    </div>
                );
            }

            return <p className="t-paragraph-hint">{servicesText}</p>;
        }
    };

    const openingHoursInfo = getOpeningHoursInfo(location);
    const openingHoursInfoString = openingHoursInfo?.statusText;
    const shouldHighLight = !!openingHoursInfo?.shouldHighLight;

    const primaryCtaMap = {
        default: {
            component: (
                <DefaultPrimaryCta
                    onOrderClick={() => onOrderClick(location)}
                    isOnlineOrderAvailable={isOnlineOrderAvailable}
                    locationSet={locationSet}
                    redirectUrl={backToUrl}
                    openingHoursInfo={openingHoursInfo}
                />
            ),
        },
        '/account/deals': {
            component: (
                <DealsPrimaryCta
                    locationSet={locationSet}
                    redirectUrl="/account/deals"
                    hasLocationOffers={
                        rewards?.rewards?.allLocationsHaveOffers ||
                        rewards?.rewards?.locationsWithOffers?.includes(locationId)
                    }
                    isRewardsLoading={rewards?.isLoading}
                    isOnlineOrderAvailable={isOnlineOrderAvailable}
                    dataTestId={`location-item-deals-button-${locationId}`}
                    locationId={locationId}
                    displayName={displayName}
                />
            ),
        },
    };

    const primaryCtaMapValue = primaryCtaMap[backToUrl] || primaryCtaMap.default;

    return (
        <div
            className={classnames(styles.container, { [styles.selected]: selected })}
            ref={elementRef}
            role="button"
            aria-label={`Select ${displayName} location`}
            onClick={handleSelectLocation}
            onKeyPress={handleSelectLocationKeyPress}
        >
            <LocationsListItemLayout
                title={renderTitle()}
                address={
                    <p title={address} className={classnames(ADDRESS_LINE_CLASSNAME, styles.address)}>
                        {address}
                    </p>
                }
                primaryCta={primaryCtaMapValue.component}
                secondaryCta={renderSecondaryCta()}
                openingHours={
                    location.status && (
                        <span
                            className={classnames(styles.openingHours, {
                                [styles.unavailableOpeningHours]: !openingHoursInfoString && SHOW_UNAVAILABLE,
                                [styles.highlight]: shouldHighLight,
                            })}
                        >
                            {openingHoursInfoString || (SHOW_UNAVAILABLE ? UNAVAILABLE_MESSAGE : null)}
                        </span>
                    )
                }
                distance={<span className={styles.distance}>{distanceString}</span>}
                storeId={
                    isLocationListEnhancementEnabled() ? undefined : (
                        <span className={styles.storeId}>{`Store ID: ${locationId}`}</span>
                    )
                }
                services={renderServices()}
                referencePoint={
                    contactDetails.address.referencePoint ? (
                        <p title={contactDetails.address.referencePoint} className="truncate t-paragraph-small">
                            {contactDetails.address.referencePoint}
                        </p>
                    ) : null
                }
            />
        </div>
    );
};

export default LocationsListItem;
